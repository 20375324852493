import titleAssembly from './components/titleAssembly';
import richAssembly from './components/richAssembly';
import pictureAssembly from './components/pictureAssembly';
import graphAssembly from './components/graphAssembly';
import atlasAssembly from './components/atlasAssembly';
import videoAssembly from './components/videoAssembly';
import telephoneAssembly from './components/telephoneAssembly';
import qrcodeAssembly from './components/qrcodeAssembly';
import formAssembly from './components/formAssembly';
import comPopup from './components/popup';
import popupPicture from 'view/landPage/components/popupPicture';
import logic from 'view/landPage/components/logic';
// 接口
import * as apis from '@/api/land';
import html2canvas from 'html2canvas';
export default {
  components: {
    titleAssembly,
    richAssembly,
    pictureAssembly,
    graphAssembly,
    atlasAssembly,
    videoAssembly,
    telephoneAssembly,
    qrcodeAssembly,
    formAssembly,
    comPopup,
    popupPicture,
    logic
  },
  data() {
    return {
      urls: '',
      sethref: '',
      setting: '',
      strategyLists: '',
      contents: [],
      popcontent: '',
      popsetting: '',
      id: '',
      token: 'nbhIWVyw',
      ad_id: '',
      // 对应 URL 参数：adid
      creative_id: '',
      // 对应 URL 参数：creativeid
      creative_type: '',
      // 对应 URL 参数：creativetype
      click_id: '',
      // 对应 URL 参数：clickid
      channel_param: '45EM',
      // 对应 URL 参数：_cl
      user_wechat_id: '',
      // 用户微信id（如果页面有二维码，则需必填）
      wechat_package_id: '',
      //微信包id
      wechat_package_rule_target_id: '',
      //
      uid: '',
      //	用户id （存浏览器本地）
      visitContent: '',
      tid: '',
      landingPageId: 0,
      pageType: true,
      biz: '',
      key: '',
      loop: '',
      modelType: '',
      isIframe: false,
      onLoad: 0,
      urlParams: null
    };
  },
  watch: {
    onLoad() {
      if (this.onLoad === 2) {
        if (this.pageType && this.modelType === 'ios') {
          this.$nextTick(() => {
            setTimeout(() => {
              this.html2canvas();
            }, 1000);
          });
        }
      }
    }
  },
  created() {
    this.getModel();
    this.init();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        // 基准大小
        const baseSize = 16;
        window.screenWidth = document.documentElement.clientWidth;
        // 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。
        const scale = window.screenWidth / 375;
        document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
      })();
    };
  },
  methods: {
    toSupport() {
      this.$router.push({
        path: '/support'
      });
    },
    async html2canvas() {
      const element = this.$refs.picture;
      const that = this;

      // 生成快照
      function convertToImage(container, options = {}) {
        // 设置放大倍数
        const scale = window.devicePixelRatio;
        // 传入节点原始宽高
        const _width = container.offsetWidth;
        const _height = container.offsetHeight;
        let {
          width,
          height
        } = options;
        width = width || _width;
        height = height || _height;

        // html2canvas配置项
        const ops = {
          scale,
          // width,
          // height,
          useCORS: true,
          allowTaint: false,
          ...options
        };
        html2canvas(container, ops).then(canvas => {
          // 返回图片的二进制数据
          that.urls = canvas.toDataURL('image/png');
        }).catch(e => {
          console.log(e);
        });
      }

      // 调用函数，取到截图的二进制数据，对图片进行处理（保存本地、展示等）
      await convertToImage(element);
    },
    getModel() {
      // 判断是在手机还是电脑
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (flag != null) {
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          this.modelType = 'ios';
        } else {
          this.modelType = 'android';
        }
      }
    },
    init() {
      const uid = localStorage.getItem('wechat_uid');
      if (uid != null) this.uid = uid;

      // let copy = 'http://localhost:9301/#/landFront?t=nbhIWVyw&_cl=45EM&tid=6e2b4c9c-69e4-11ec-817d-00163e18a41a';
      // let url = copy.split('?')[1];
      const url = window.location.href.split('?')[1];
      if (!url) return;

      // 判断是外部浏览器还是小程序环境   pageType>0 小程序  pageType<0外部H5环境
      const pageType = url.indexOf('pageType');
      this.pageType = pageType > 0;
      if (this.pageType) {
        setTimeout(() => {
          this.$router.replace({
            path: 'jumpIndex'
          });
        }, 90000);
      }
      if (url.indexOf('&') === -1) {
        return false;
      } else {
        const paramsArr = location.href.split('?')[1].split('&');
        const params = {};
        for (const param of paramsArr) {
          if (param.split('=')[0] != 'biz' && param.split('=')[0] != '_cl' && param.split('=')[0] != 't') params[param.split('=')[0]] = param.split('=')[1];
        }
        this.urlParams = params;
        const arrs = url.split('&');
        for (let i = 0; i < arrs.length; i++) {
          const name = arrs[i].split('=')[0];
          switch (name) {
            case 't':
              this.token = arrs[i].split('=')[1];
              break;
            case '_cl':
              this.channel_param = arrs[i].split('=')[1];
              break;
            case 'ad_id':
            case 'adid':
              this.ad_id = arrs[i].split('=')[1];
              break;
            case 'creative_id':
            case 'creativeid':
              this.creative_id = arrs[i].split('=')[1];
              break;
            case 'creative_type':
            case 'creativetype':
              this.creative_type = arrs[i].split('=')[1];
              break;
            case 'click_id':
            case 'clickid':
              this.click_id = arrs[i].split('=')[1];
              break;
            case 'biz':
              this.biz = arrs[i].split('=')[1];
              break;
            case 'tid':
              this.tid = arrs[i].split('=')[1];
              break;
            case 'key':
              this.key = arrs[i].split('=')[1];
              break;
          }
        }
        if (url.indexOf('key') === -1) {
          // 落地页详情(渲染)
          this.landDetail();
        } else {
          // 预览
          this.preview();
        }
      }
    },
    // 预览
    preview() {
      this.isIframe = true;
      const parse = {
        key: this.key
      };
      apis.preview(parse).then(res => {
        const detail = res.data.detail;
        // 页面设置
        const setting = JSON.parse(detail.setting);
        this.setting = setting;
        // 页面策略
        const strategy = JSON.parse(detail.strategy);
        const strategyLists = strategy.strategyLists;
        this.strategyLists = strategyLists;

        // 组件内容
        const content = JSON.parse(detail.content);
        let wechatzhu = true;
        if (content.length) {
          content.forEach((item, index) => {
            item.hrefid = 'href' + index;
            if (item.text == '微信包') {
              this.wechat_package_id = item.setStyle.wechat;
              wechatzhu = false;
            }
          });
        }
        this.contents = content;
        if (wechatzhu && !this.pageType) {
          // 追踪
          this.visit();
        }
        if (!setting.isApplet) {
          // 页面策略配置
          for (let i = 0; i < strategyLists.length; i++) {
            if (strategyLists[i].triggerCondition === '0' || strategyLists[i].triggerCondition === 0) {
              // 访问页面展示
              if (strategyLists[i].contentType === 1 || strategyLists[i].contentType === '1') {
                // 打开弹窗
                if (strategyLists[i].pop !== '') this.arousePop(strategyLists[i].pop);
              }
            }
          }
        }
        // 页面标题
        document.title = setting.name;
      });
    },
    // 详情
    landDetail() {
      const url = window.location.href.split('?')[1];
      const parse = {
        token: this.token
      };
      apis.landDetail(parse).then(res => {
        this.landingPageId = res.data.id;
        const detail = res.data.detail;
        // 页面设置
        const setting = JSON.parse(detail.setting);
        this.setting = setting;
        // 页面策略
        const strategy = JSON.parse(detail.strategy);
        const strategyLists = strategy.strategyLists;
        this.strategyLists = strategyLists;

        // 组件内容
        const content = JSON.parse(detail.content);
        let wechatzhu = true;
        if (content.length) {
          content.forEach((item, index) => {
            item.hrefid = 'href' + index;
            if (item.text === '微信包') {
              this.wechat_package_id = item.setStyle.wechat;
              wechatzhu = false;
            }
          });
        }
        this.contents = content;
        if (wechatzhu && !this.pageType) {
          // 追踪
          this.visit();
        }
        if (!setting.isApplet) {
          // 页面策略配置
          for (let i = 0; i < strategyLists.length; i++) {
            if (strategyLists[i].triggerCondition === '0' || strategyLists[i].triggerCondition === 0) {
              // 访问页面展示
              if (strategyLists[i].contentType === '1' || strategyLists[i].contentType === 1) {
                // 打开弹窗
                if (strategyLists[i].pop !== '') this.arousePop(strategyLists[i].pop);
              }
            }
          }
        }

        // 页面标题
        document.title = setting.name;
        this.onLoad++;
      });
    },
    handleCanvas() {
      this.onLoad++;
    },
    userwechatid(e) {
      console.log(e);
      this.user_wechat_id = e.id;
      this.wechat_package_rule_target_id = e.target_id;
      this.visit();
    },
    visit() {
      const paramsArr = location.href.split('?')[1].split('&');
      const params = {};
      console.log(paramsArr);
      for (const param of paramsArr) {
        params[param.split('=')[0]] = param.split('=')[1];
      }
      const parse = {
        ...params,
        token: this.token,
        ad_id: this.ad_id,
        creative_id: this.creative_id,
        creative_type: this.creative_type,
        click_id: this.click_id,
        channel_param: this.channel_param,
        wechat_package_id: this.wechat_package_id || undefined,
        user_wechat_id: this.user_wechat_id || undefined,
        wechat_package_rule_target_id: this.wechat_package_rule_target_id || undefined,
        uid: this.uid || undefined
      };
      apis.visit(parse).then(res => {
        const data = res.data;
        this.tid = data.tid;
        localStorage.setItem('wechat_uid', data.uid);
        this.setpage();
      });
    },
    setpage() {
      const setting = this.setting;
      const strategyLists = this.strategyLists;
      console.log(setting);
      if (setting.isApplet) {
        if (setting.jumpType == 2) {
          // 页面配置 jumpType==2自动H5链接
          var urlParams = {
            ...this.urlParams
            // tid:this.tid
          };

          var url = setting.h5Url;
          for (var k in urlParams) {
            let value = urlParams[k] !== undefined ? urlParams[k] : '';
            url += `${url.indexOf('?') > -1 ? '&' : '?'}${k}=${encodeURIComponent(value)}`;
          }
          console.log(url);
          window.location.href = url;
        } else if (setting.jumpType == 3) {
          this.jumpKf(setting.wechatKf);
        } else if (setting.jumpType == 1) {
          // 页面配置 jumpType==1自动跳转小程序
          this.openWechat(setting.appletlink, setting.appletUrl, setting.landsel, setting.channel);
        } else if (setting.jumpType == 4) {
          this.goHuoke(setting.acid);
        }
      } else {
        // 页面策略配置
        for (let i = 0; i < strategyLists.length; i++) {
          if (strategyLists[i].triggerCondition === '0' || strategyLists[i].triggerCondition === 0) {
            // 访问页面展示
            if (strategyLists[i].contentType === '0' || strategyLists[i].contentType === 0) {
              // url跳转
              if (strategyLists[i].urlLink !== '') {
                window.location.href = strategyLists[i].urlLink;
              }
            } else if (strategyLists[i].contentType === '2' || strategyLists[i].contentType === 2) {
              // 打开小程序
              this.openWechat(strategyLists[i].appletlink, strategyLists[i].appletUrl, strategyLists[i].ground, strategyLists[i].channel);
            }
          }
        }
      }
    },
    // 网页跳转
    openUrlLink(url) {
      let href;
      if (url.indexOf('?') !== -1 && url.indexOf('=') !== -1) {
        href = `${url}&tid=${this.tid}`;
      } else {
        href = `${url}?tid=${this.tid}`;
      }
      window.location.href = href;
    },
    jumpKf(wechatKf) {
      const params = {
        kf_id: wechatKf,
        tid: this.tid
      };
      apis.getWechatKfUrl(params).then(res => {
        console.log(res);
        if (res.data.url) {
          // debugger
          window.location.href = res.data.url;
        }
      });
    },
    jumpCustomerAcquisition(row) {
      console.log(row);
      // 获客助手跳转
      var urlqz = 'weixin://biz/ww/profile/';
      var urlhz = row.customerAcquisitionUrl + '?customer_channel=' + this.tid + '.' + localStorage.getItem('wechat_uid');
      var str = row.customerAcquisitionUrl.split("/");
      var targetStr = str[str.length - 1];
      if (row.link_id) {
        urlhz += '.' + row.link_id;
      } else {
        urlhz += '.' + targetStr;
      }
      var url = urlqz + encodeURIComponent(urlhz);
      window.location.href = url;
      // try{
      //   var el = document.createElement("a");
      //   document.body.appendChild(el);
      //   el.href = url; 
      //   el.target = '_self'; 
      //   el.click();
      //   document.body.removeChild(el);
      // }catch{
      //   window.location.href = url;
      // }
    },

    goHuoke(acid) {
      const params = {
        acid: acid,
        tid: this.tid
      };
      apis.getAcquisitionUrl(params).then(res => {
        console.log(res);
        if (res.data.url) {
          // debugger
          window.location.href = res.data.url;
        }
      });
    },
    arousePop(widget_template_id) {
      // 小工具模版详情
      const parse = {
        widget_template_id: widget_template_id
      };
      apis.widgetTemplate(parse).then(res => {
        this.popcontent = JSON.parse(res.data.content);
        this.popsetting = JSON.parse(res.data.setting);
        // 显示弹窗
        this.$refs.comPopup.bool = true;
        this.$refs.comPopup.iszhezhao = true;
      });
    },
    // 跳转微信小程序
    openWechat(applet_link, open_link, landing_page_id, channel_id) {
      if (applet_link && applet_link === 1) {
        const parse = {
          tid: this.tid,
          open_link: open_link // 落地页公众号链接
        };

        if (this.tid == '') {
          return;
        }
        // this.html2canvas()
        apis.generateOfficial(parse).then(res => {
          if (res.data.url_scheme) {
            if (this.modelType === 'ios') {
              location.href = res.data.url_scheme;
            } else {
              window.location.href = res.data.url_scheme;
            }
          }
          if (res.data.tid) {
            this.tid = res.data.tid;
          }
        });
        return;
      }
      const parse = {
        tid: this.tid,
        // landing_page_id: landing_page_id, // 落地页id
        // channel_id: channel_id, // 落地页渠道id
        landing_page_id: landing_page_id ? landing_page_id : applet_link,
        // 落地页id
        channel_id: channel_id ? channel_id : open_link // 落地页渠道id
      };
      // this.html2canvas()
      apis.generateScheme(parse).then(res => {
        if (res.data.url_scheme) {
          if (this.modelType === 'ios') {
            location.href = res.data.url_scheme;
          } else {
            // 添加异步+时间戳解决可能缓存带来的跳转无效问题
            // setTimeout(()=>{
            window.location.href = res.data.url_scheme + '&a=' + new Date().getTime();
            // },100)
          }
        }

        if (res.data.tid) {
          this.tid = res.data.tid;
        }
      });
    },
    // 长按事件
    start() {
      clearTimeout(this.loop); // 再次清空定时器，防止重复注册定时器
      this.loop = setTimeout(() => {
        // 追踪-其他
        this.trace();
        clearTimeout(this.loop); // 清空定时器，防止重复注册定时器
      }, 750);
    },
    // end() {
    //   clearTimeout(this.loop) // 清空定时器，防止重复注册定时器
    // },
    // 小程序追踪
    trace() {
      if (!this.pageType) return false;
      const parse = {
        tid: this.tid,
        step: '40'
      };
      apis.trace(parse).then(res => {});
    }
  }
};